import { GetSlugsQuery } from '@/graphql/queries';
import { getClient } from '@/utils/data-fetching';

const staticSlugs = ['search-page', 'contact'];

const staticSlugsWithTypes = [
  {
    ogSlug: 'search-page',
    slug: 'search-page',
    type: 'search-page',
    __typename: 'search-page',
  },
  {
    ogSlug: 'not-found',
    slug: 'not-found',
    type: 'not-found',
    __typename: 'Not Found',
  },
];

export const getSlugs = async (slug?: string) => {
  const fetchClient = getClient();

  const { slugs, withTypes } = await fetchClient
    .query({
      query: GetSlugsQuery,
      variables: {
        preview: true,
        slug: [slug, `/${slug}`],
      },
    })
    .then((res) => {
      const slugs = [
        ...res.data.simpleEventCollection.items
          ?.filter((item: any) => !!item.slug || !!item.sys.publishedAt)
          ?.map((item: { slug: string }) => {
            if (item?.slug?.length > 1 && item.slug.startsWith('/')) {
              return item?.slug?.slice(1);
            }
            if (!item.slug.includes('do-not-delete')) return item.slug;
          })
          .filter((item: string) => item !== undefined),
        ...res.data.venueCollection.items
          ?.filter((item: any) => !!item.title || !!item.sys.publishedAt)
          ?.map((item: { title: string }) => {
            if (item?.title?.length > 1) {
              return 'venue/' + item?.title;
            }
          })
          .filter((item: string) => item !== undefined),
        ...res.data.genreCollection.items
          ?.filter((item: any) => !!item.title || !!item.sys.publishedAt)
          ?.map((item: { title: string }) => {
            if (item?.title?.length > 1) {
              return 'genre/' + item?.title;
            }
          })
          .filter((item: string) => item !== undefined),
        ...res.data.flexiblePageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map((item: { slug: string }) => {
            if (item.slug.length > 1 && item.slug.startsWith('/')) {
              return item.slug.slice(1);
            }
            if (!item.slug.includes('do-not-delete')) return item.slug;
          })
          .filter((item: string) => item !== undefined),
        ...res.data.pageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map((item: { slug: string }) => {
            if (item.slug.length > 1 && item.slug.startsWith('/')) {
              return item.slug.slice(1);
            }
            if (!item.slug.includes('do-not-delete')) return item.slug;
          })
          .filter((item: string) => item !== undefined),
        ...res.data.previewPageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map((item: { slug: string }) => {
            if (item.slug.length > 1 && item.slug.startsWith('/')) {
              return item.slug.slice(1);
            }
            if (!item.slug.includes('do-not-delete')) return item.slug;
          })
          .filter((item: string) => item !== undefined),
      ];
      const withTypes = [
        ...res.data.simpleEventCollection.items
          ?.filter((item: any) => !!item.slug || !!item.sys.publishedAt)
          ?.map(
            (item: {
              slug: string;
              __typename: string;
              sys: {
                id: string;
              };
            }) => {
              if (item.slug.length > 1 && item.slug.startsWith('/')) {
                return {
                  ogSlug: item.slug,
                  slug: item.slug.slice(1),
                  __typename: item.__typename,
                };
              }
              if (!item.slug.includes('do-not-delete'))
                return {
                  ogSlug: item.slug,
                  slug: item.slug,
                  __typename: item.__typename,
                  id: item?.sys?.id,
                };
            },
          )
          .filter((item: string) => item !== undefined),
        ...res.data.flexiblePageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map(
            (item: {
              slug: string;
              __typename: string;
              sys: {
                id: string;
              };
            }) => {
              if (item.slug.length > 1 && item.slug.startsWith('/')) {
                return {
                  ogSlug: item.slug,
                  slug: item.slug.slice(1),
                  __typename: item.__typename,
                };
              }
              if (!item.slug.includes('do-not-delete'))
                return {
                  ogSlug: item.slug,
                  slug: item.slug,
                  __typename: item.__typename,
                  id: item?.sys?.id,
                };
            },
          )
          .filter((item: string) => item !== undefined),
        ...res.data.pageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map(
            (item: {
              slug: string;
              __typename: string;
              type: string;
              sys: {
                id: string;
              };
            }) => {
              if (item.slug.length > 1 && item.slug.startsWith('/')) {
                return {
                  ogSlug: item.slug,
                  slug: item.slug.slice(1),
                  __typename: item.__typename,
                };
              }
              if (!item.slug.includes('do-not-delete'))
                return {
                  ogSlug: item.slug,
                  slug: item.slug,
                  type: item.type,
                  __typename: item.__typename,
                  id: item?.sys?.id,
                };
            },
          )
          .filter((item: string) => item !== undefined),
        ...res.data.previewPageCollection.items
          ?.filter((item: any) => !!item.slug)
          ?.map(
            (item: {
              slug: string;
              __typename: string;
              sys: { id: string };
            }) => {
              if (item.slug.length > 1 && item.slug.startsWith('/')) {
                return {
                  ogSlug: item.slug,
                  slug: item.slug.slice(1),
                  type: 'PreviewPage',
                  __typename: item.__typename,
                };
              }
              if (!item.slug.includes('do-not-delete'))
                return {
                  ogSlug: item.slug,
                  slug: item.slug,
                  type: 'PreviewPage',
                  id: item.sys.id,
                  __typename: item.__typename,
                };
            },
          )
          .filter((item: string) => item !== undefined),
      ];

      return { slugs, withTypes };
    })
    .catch((err) => {
      console.log('Error in getSlugs.ts');
      console.log(err);
      // console.log(err?.networkError?.result?.errors);
      return { slugs: [], withTypes: [] };
    });

  const replacedSlugsWithTypes = withTypes.map((item) => {
    staticSlugsWithTypes.forEach((staticSlug) => {
      if (item.slug === staticSlug.slug) {
        item.type = staticSlug.type;
      }
    });
    return item;
  });

  return {
    slugs: [...slugs, ...staticSlugs],
    withTypes: [...replacedSlugsWithTypes, ...staticSlugsWithTypes],
  };
};
